function traductionPhrase(fr,en){
    var language= $("#language").val();
    switch(language){
        case "EN":
            return en;

        default:
            return fr;
    }
}

function cleanupYesNoModal() {
    $("#btnOkModalYesNo").html("Ok")
    $("#btnCancelModalYesNo").html("Cancel")
    $("#btnOkModalYesNo").off('click');
    $("#btnCancelModalYesNo").off('click');
    $("#modalYesNo").off('hidden.bs.modal');
}

function buildErrorMessage(error) {
    return `${traductionPhrase("Erreur","Error")} ${error.status} : ${error.responseText}`;
}

function showErreurModal(error, onClose) {
    cleanupYesNoModal();
    $("#btnCancelModalYesNo").css("display","none");
    $("#yesNoModalLabel").html(traductionPhrase("Attention","Warning"));
    $("#bodyModalYesNo").html(error || traductionPhrase("Erreur","Error"));
    $("#modalYesNo").modal("show");
    $("#modalYesNo").one('hidden.bs.modal', function() {
        cleanupYesNoModal();
        if (onClose) {
            onClose();
        }
    });
}

function showCustomAlertModal(message, callbackThen, callbackCatch, nameOkButton, nameCancelButton, title){
    let result = false;
    cleanupYesNoModal();

    if(nameOkButton && typeof nameOkButton === 'string'){
        $("#btnOkModalYesNo").html(nameOkButton)
    }
    if(nameCancelButton && typeof nameCancelButton === 'string'){
        $("#btnCancelModalYesNo").html(nameCancelButton)
    }

    $("#btnCancelModalYesNo").css("display","block");
    if(title && typeof title === 'string'){
        $("#yesNoModalLabel").html(title)
    } else {
        $("#yesNoModalLabel").html(traductionPhrase("Attention","Warning"));
    }
    $("#bodyModalYesNo").html(message);
    $("#modalYesNo").modal("show");
    $("#btnOkModalYesNo").one('click', function() {
        result = true;
    });
    $("#modalYesNo").one('hidden.bs.modal', function() {
        cleanupYesNoModal();
        if (result) {
            if (callbackThen) {
                callbackThen('ok');
            }
        } else {
            if (callbackCatch) {
                callbackCatch();
            }
        }
    });
}

function emptyCatchCallBack(ex){
    
}


